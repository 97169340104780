import * as React from 'react';
import { useState } from 'react';
import {
    Svg
} from './svgIcons.js'


const Carousel: React.FunctionComponent<{imageUrls: string[], onClose: () => void}> = ({imageUrls, onClose}) => {

    const [imagePosition, setPosition] = useState<number>(0)

    let modulo = (x: number, m: number) => (((x % m) + m) % m);
    
    const next = () => setPosition(modulo(imagePosition + 1, imageUrls.length))
    const prev = () => setPosition(modulo(imagePosition - 1, imageUrls.length))

    return (
        <div className="bg-opacity-75 bg-black w-full fixed top-0 h-screen z-10">
            <div className="flex justify-center w-full h-full">
                <div className="flex flex-row justify-center w-full mx-auto">
                    <img className="flex-shrink md:w-10/12 object-contain" src={imageUrls[imagePosition]} />
                </div>
            </div>

            <div className="w-full fixed flex justify-center md:mx-auto space-x-10 pb-5 md:pb-20 bottom-10">
                <button onClick={prev} className="ml-10 bg-grey-light text-white text-xs py-1 px-2 rounded inline-flex items-center bg-brand-primary hover:bg-brand-primary-darkest">
                    <div className="w-4 h-4"> <Svg icon='arrow-left'/> </div>
                </button>
                <button onClick={() => onClose()} className="bg-grey-light text-white py-1 px-2 rounded inline-flex items-center bg-brand-primary hover:bg-brand-primary-darkest text-xs">
                    <div className="w-4 h-4 mr-2"> <Svg icon='close'/> </div> <span> fermer </span>
                </button>
                <button onClick={next} className="mr-10 bg-grey-light text-white text-xs py-1 px-2 rounded inline-flex items-center bg-brand-primary hover:bg-brand-primary-darkest">
                    <div className="w-4 h-4"> <Svg icon='arrow-right'/> </div> 
                </button>
            </div> 
        </div>
    )
}

export default Carousel
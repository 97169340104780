import React, {useContext, useEffect, useState} from "react"
import { httpApiGateway } from "../adapters/secondary/httpEshopApi";
import { BlogPost, CategoryTree, Product, StaticContext } from "../domain/interfaces";
import ProductPage from "./productpage"

export default function Home(props: any) {

  const productSlug = props.params.slug
  const api = new httpApiGateway()

  const [context, setContext] = useState<StaticContext | null>(null)
  const [product, setProduct] = useState<Product | null>(null)

  useEffect(() => {
    api.retrieveStaticContext().then(setContext)
    api.retrieveProduct(productSlug).then(setProduct)
  }, []) // given empty array useEffect run only once

  return context && product && <ProductPage pageContext={{
      staticContext: context,
      product: product,
      breadcrumbs: []
  }}/>

}

import React, { useState } from "react"
import { BlogPost, Breadcrumb, Product, StaticContext } from "../domain/interfaces"
import HeaderMenu from "../components/Header"
import BlogCardBanner from "../components/BlogCardBanner"
import Carousel from "../components/Carousel"
import Footer from "../components/Footer"
import Basket from "../components/Basket"
import {Svg} from "../components/svgIcons"
import { Helmet } from "react-helmet"
import {connect} from "react-redux"
import {addProductToBasket} from "./../usecases/basket"

const connector = connect()

const ProductPage: React.FunctionComponent<{pageContext: {staticContext: StaticContext, product: Product, breadcrumbs: Breadcrumb[]}, dispatch: any}> = ({pageContext, dispatch}) => {

    const {staticContext, breadcrumbs, product} = pageContext
    let [carrousel, displayCarrousel] = useState<boolean>(false)
    
    return (    
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Chamantinis Bijoux à la Réunion| {product.name} </title>
            </Helmet>
            <HeaderMenu contact={staticContext.contact} menu={staticContext.categories}/>

            { carrousel && <Carousel imageUrls={
                    [   
                        ...product.images.map(image => image.larger.imgUrl),
                    ]
                }
                onClose={() => displayCarrousel(false)} 
            /> }

            <div className="w-full max-w-screen-lg mx-auto pl-2 md:pl-0 pt-2 md:pt-10 text-gray-800 cursor-pointer text-sm">
                {breadcrumbs.map( breadcrumb => <span> / <a key={breadcrumb.name} href={breadcrumb.href} className="hover:text-brand-primary">  {breadcrumb.name}  </a> </span> )}
            </div>
                <div className="w-full max-w-screen-lg mx-auto pt-10 flex flex-col md:flex-row">
                    <div className="w-full pl-2 md:hidden pb-5">
                        <h1 className="text-2xl"> {product.name} </h1>
                        <span className="text-xs text-gray-500"> {product.reference} - </span>
                        <span className="text-xs text-gray-500"> STOCK DISPONIBLE {product.stockAvailable} </span> <br/>
                        {product.priceBeforePromo && <span className="text-brand-primary-darker text-md font-bold"> PROMO <br/> </span> }
                        {product.priceBeforePromo && <span className="font-bold text-sm line-through mr-2 text-gray-500"> {product.priceBeforePromo}{product.currencySymbol} TTC </span> }
                        <span> {product.priceWithTaxFormat} {product.currencySymbol} </span>
                    </div>
                    <div className="w-full md:w-1/2 px-2 md:px-2">
                        <img className="rounded w-full" onClick={() => displayCarrousel(true)} src={product.coverImage.medium.imgUrl} />
                        <div className="flex flex-wrap mt-2 md:mt-10">
                            {product.images.map((image) => (
                                <img onClick={() => displayCarrousel(true)}  className="w-20 h-20 m-1" src={image.medium.imgUrl} />
                            ))}
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 pl-2 md:pl-4">
                        <h1 className="text-2xl hidden md:block"> {product.name} </h1>
                        <span className="text-xs text-gray-500"> {product.reference} - </span>
                        <span className="text-xs text-gray-500"> STOCK DISPONIBLE {product.stockAvailable} </span> <br/>
                        {product.priceBeforePromo && <span className="text-brand-primary-darker text-md font-bold"> PROMO <br/> </span> }
                        {product.priceBeforePromo && <span className="font-bold text-sm line-through mr-2 text-gray-500"> {product.priceBeforePromo}{product.currencySymbol} TTC </span> }
                        <span className="hidden md:block"> {product.priceWithTaxFormat} {product.currencySymbol} </span>
                        <div className="mt-2 text-gray-700 productpage-description" dangerouslySetInnerHTML={{__html: '<div>'+product.descriptionHTML+'</div>'}} />
                        <div className="flex flex-row space-x-4">
                            <img className="rounded w-12 mt-4" onClick={() => displayCarrousel(true)} src={'/chamantini/logo-handcraft.png'} />
                            <img className="rounded w-12 mt-4" onClick={() => displayCarrousel(true)} src={'/chamantini/logo-unique.png'} />
                        </div>
                        <p className="text-center md:text-left pt-10">
                            { product.stockAvailable > 0 && <button onClick={()=>dispatch(addProductToBasket(product.id))} className="bg-grey-light text-white py-2 px-4 rounded inline-flex items-center bg-brand-primary hover:bg-brand-primary-darkest">
                                <div className="w-4 h-4 mr-2"> <Svg icon='shopping-bag'/> </div>
                                <span>Ajouter au panier</span>
                            </button> } 
                            { product.stockAvailable <= 0 && <button className="bg-grey-light text-white py-2 px-4 rounded inline-flex items-center bg-brand-gray-lightest">
                                <div className="w-4 h-4 mr-2"> <Svg icon='shopping-bag'/> </div>
                                <span>Victime de son succès</span>
                            </button> } 
                        </p>
                    </div>
                </div>

            
            {/* <ProductMiniatureBanner banner={this.state.relatedProductBanner}/> */}
            <BlogCardBanner banner={{title: "Nos derniers articles", cards: staticContext.blogPosts}} />
            <Footer contact={staticContext.contact} group1={staticContext.footer.group1} group2={staticContext.footer.group2} group3={staticContext.footer.group3} />
        </div>
    )

}

export default connector(ProductPage)